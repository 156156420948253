<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form ref="form" @submit.prevent="handleSubmit(saveGroup)" autocomplete="off">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>{{ $t(modalTitle(name)) }} <strong>{{ $tc('menu.groups') }}</strong></span>
				</h4>
				<v-swatches v-model="form.color" :swatches="swatches" row-length="6" popover-x="left" show-fallback fallback-input-type="color"></v-swatches>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="modal-card mb-3">
					<InputWithValidation rules="required" :label="$tc('fields.name')" v-model="form.name" />

					<small class="modal-updated" v-if="form.updated_at">{{ $t('labels.last_change') }} {{ format(form.updated_at) }}</small>
				</div>
			</div>
			<Footer :loading="loading" />
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import VSwatches from 'vue-swatches'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import Footer from '@/components/modals/Footer'
import '@/mixins/generic'

export default {
	components: {
		Footer,
		InputWithValidation,
		ValidationObserver,
		VSwatches
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			swatches: this.defaultColors(),
			isOpening: false,
			loading: false,
			permission: [],
			visible: false,
			form: {
				name: ''
			}
		}
	},
	methods: {
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const response = await Api.get(`groups/findById/${this.id}`)
					if (response.status === 200) {
						const { data } = response
						this.form.name = data.name
						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
				}
			}
		},
		async update() {
			try {
				this.loading = true

				let formData = new FormData()
				formData.append('_method', 'put')
				formData.append('name', this.form.name)

				const response = await Api.post(`groups/update/${this.id}`, formData)
				const { status } = response
				if ([200, 201].includes(status)) {
					this.$emit('close')
					history.pushState({}, '', '/groups')
					successToast(this.$t('alerts.update.success', [this.$tc('menu.groups')]))
					eventHub.$emit('reload-groups')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					errorToast(this.$t('alerts.update.error', [this.$tc('menu.groups')]))
				}
			} finally {
				this.loading = false
			}
		},
		async store() {
			try {
				this.loading = true

				let formData = new FormData()
				formData.append('name', this.form.name)

				const response = await Api.post('groups/store', formData)
				const { status } = response
				if ([200, 201].includes(status)) {
					this.$emit('close')
					history.pushState({}, '', '/groups')
					successToast(this.$t('alerts.create.success', [this.$tc('menu.groups')]))
					eventHub.$emit('reload-groups')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					errorToast(this.$t('alerts.create.error', [this.$tc('menu.groups')]) + '<small>' + message + '</small>')
				}
			} finally {
				this.loading = false
			}
		},
		async saveGroup() {
			this.name === 'New' ? await this.store() : await this.update()
		}
	},
	mounted() {
		this.findById()
	}
}
</script>
